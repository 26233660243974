import Vue from 'vue'
import App from './App.vue'
import router from './router/route.js'
import store from './store'
//import 'vant/lib/index.css'
import './assets/css/reset.css'
import './assets/css/base.scss'
import './assets/css/head.scss' //头部的样式

//过滤器
import * as filters from './api/filters'
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

import vDrag from './api/drag'
//import VueCookies from 'vue-cookies' 




import VueClipboard from 'vue-clipboard2';//Vue中配合clipboard.js实现点击按钮复制内容到剪切板
VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard);

//引用element-ui组件
//import ElementUI from 'element-ui';
import ElementUI from './api/my_element_ui.js'


import 'element-ui/lib/theme-chalk/index.css';
//import './components/element.js'
Vue.use(ElementUI);



Vue.config.productionTip = false


Vue.directive('drag', vDrag)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

