<template> 
    <!-- 公告 -->
    <div id="app_notice">
        <el-row v-if="content != ''" @click.native="notice_list_open()" type="flex" align="middle" class="font_size3 cursor"
           style="height:40px;line-height:40px;padding-left:5%;padding-right:5%;width:100%;color: purple;position:fixed;top:63px;background-color:#F0F0F0;opacity:0.8;z-index:999;">
            <i class="el-icon-bell" style="margin-right:20px;font-size:30px;"></i>
            <div style="width:93%;" class="chao_wen_zi_div_hide">{{content}}</div>
        </el-row>

        <!-- 公告列表 组件 -->
        <NoticeList :prop_change_i="prop_notice_list_i" @result="notice_list_result"></NoticeList>

    </div>
</template>
<script>

import API from '../../api/api';
import NoticeList from './notice_list.vue';
import {
    getCookieSession
} from '../../api/tool.js';

import router from '../../router/route.js'

export default {
    components: {
        NoticeList
    },
    data(){
        return{
            content:"",//公告内容
            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度

            //公告列表 组件
            prop_notice_list_i:0,//公告列表 组件 弹出框是否可见

            timed:'',//定时任务
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();
        //获取数据
        this.getData();

        //定时任务-启动
        this.setTiming();
    },
    //销毁时，执行
    destroyed(){
        console.log("销毁时，执行");
        window.clearInterval(this.timed);//销毁定时任务
    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 220);
        },

        //设置定时器
        setTiming(){
            console.log("设置定时器");
            this.timed = window.setInterval(() => {
                this.getData();//获取数据
                // setTimeout(() =>{
                //     this.getData();//获取数据
                // }, 0)
            }, 60000)//60s获取一次
        },

        //获取数据
        getData(){
            //console.log("路由地址："+router.currentRoute.fullPath);

            var session = getCookieSession();//获取cookie中登陆的session信息
            if(session == "" || router.currentRoute.fullPath == "/"){
                this.content = "";
            }else{

                //请求接口
                API.AnnounceServlet({
                    param: "recent",
                    loading_onoff:false
                }).then((res) => {
                    //console.log("公告："+res);
                    if(res){
                        if (res.recode === 0) {
                            this.content = res.content;
                        } 
                    }
                    
                    
                    
                });
            }

            
        },
        //公告列表  打开
        notice_list_open(){
            console.log("公告列表  打开");
            this.prop_notice_list_i ++;//公告列表 组件 弹出框是否可见
        },

        //公告列表 处理结果
        notice_list_result(){
            //获取数据
            this.getData();
        },
    }
};

</script>

<style scoped>

</style>