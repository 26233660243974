import Vue from 'vue'
import VueRouter from 'vue-router'

import {getItem} from '../api/tool'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('../views/login.vue'),
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('../views/index.vue'),
  },
  {
    path: '/user_index',
    name: 'user_index',
    component: () => import('../views/user/user_index.vue'),
  },
  {
    path: '/user_company_list',
    name: 'user_company_list',
    component: () => import('../views/user/user_company_list.vue'),
  },
  {
    path: '/user_list',
    name: 'user_list',
    component: () => import('../views/user/user_list.vue'),
  },
  { //
    path: '/user_detail/:id',
    name: 'user_detail',
    component: () => import('../views/user/user_detail.vue'),
  },
  { //
    path: '/user_son_list/:id/:cpidTwo',
    name: 'user_son_list',
    component: () => import('../views/user/user_son_list.vue'),
  },
  { //
    path: '/user_son_detail/:id/:cpidTwo/:cpidThree',
    name: 'user_son_detail',
    component: () => import('../views/user/user_son_detail.vue'),
  },
  { //客户价格批量导入
    path: '/user_price_import',
    name: 'user_price_import',
    component: () => import('../views/user/user_price_import.vue'),
  },
  { //客户价格刷选
    path: '/user_price_search',
    name: 'user_price_search',
    component: () => import('../views/user/user_price_search.vue'),
  },
  { //客户重发策略管理
    path: '/user_repeat_strategy_list',
    name: 'user_repeat_strategy_list',
    component: () => import('../views/user/user_repeat_strategy_list.vue'),
  },
  { //同步状态管理
    path: '/sync_state_list',
    name: 'sync_state_list',
    component: () => import('../views/user/sync_state_list.vue'),
  },
  { //客户信息变更通知
    path: '/user_notice',
    name: 'user_notice',
    component: () => import('../views/user/user_notice.vue'),
  },
  { //客户与通道公司关联管理 
    path: '/user_channel_company_list/:page_type',
    name: 'user_channel_company_list',
    component: () => import('../views/user/user_channel_company_list.vue'),
  },
  { //通道菜单
    path: '/channel_index',
    name: 'channel_index',
    component: () => import('../views/channel/channel_index.vue'),
  },
  { //通道配置
    path: '/channel_list',
    name: 'channel_list',
    component: () => import('../views/channel/channel_list.vue'),
  },
  { //通道详情
    path: '/channel_detail/:id',
    name: 'channel_detail',
    component: () => import('../views/channel/channel_detail.vue'),
  },
  { //失败状态转义
    path: '/channel_state_index',
    name: 'channel_state_index',
    component: () => import('../views/channel/channel_state_index.vue'),
  },
  { //企信通平台失败状态转义策略表
    path: '/channel_state_sync',
    name: 'channel_state_sync',
    component: () => import('../views/channel/channel_state_sync.vue'),
  },
  { //企信通平台自定义失败状态
    path: '/channel_state_unify',
    name: 'channel_state_unify',
    component: () => import('../views/channel/channel_state_unify.vue'),
  },
  { //失败状态列表
    path: '/state_list',
    name: 'state_list',
    component: () => import('../views/channel/state_list.vue'),
  },
  { //黑签名列表
    path: '/channel_stop_sign_list',
    name: 'channel_stop_sign_list',
    component: () => import('../views/channel/channel_stop_sign_list.vue'),
  },
  { //黑模板列表
    path: '/channel_stop_template_list',
    name: 'channel_stop_template_list',
    component: () => import('../views/channel/channel_stop_template_list.vue'),
  },
  { //短信逃生(消息队列处理)
    path: '/channel_queue_handle_list',
    name: 'channel_queue_handle_list',
    component: () => import('../views/channel/channel_queue_handle_list.vue'),
  },
  { //预付费通道余额
    path: '/channel_fee_list',
    name: 'channel_fee_list',
    component: () => import('../views/channel/channel_fee_list.vue'),
  },
  
  { //通道签约公司
    path: '/channel_company_list',
    name: 'channel_company_list',
    component: () => import('../views/channel/channel_company_list.vue'),
  },
  { //通道效果回传管理
    path: '/channel_backhaul_index',
    name: 'channel_backhaul_index',
    component: () => import('../views/channel/channel_backhaul/channel_backhaul_index.vue'),
  },
  { //通道效果回传配置
    path: '/channel_backhaul_list',
    name: 'channel_backhaul_list',
    component: () => import('../views/channel/channel_backhaul/channel_backhaul_list.vue'),
  },
  { //通道效果回传统计
    path: '/channel_backhaul_stat',
    name: 'channel_backhaul_stat',
    component: () => import('../views/channel/channel_backhaul/channel_backhaul_stat.vue'),
  },
  { //通道效果回传上传文件列表
    path: '/channel_backhaul_upload_list',
    name: 'channel_backhaul_upload_list',
    component: () => import('../views/channel/channel_backhaul/channel_backhaul_upload_list.vue'),
  },
  { //通道效果回传上传文件
    path: '/channel_backhaul_upload',
    name: 'channel_backhaul_upload',
    component: () => import('../views/channel/channel_backhaul/channel_backhaul_upload.vue'),
  },

  { //运维管理》菜单页
    path: '/operation_index',
    name: 'operation_index',
    component: () => import('../views/operation/operation_index.vue'),
  },
  { //运维管理》承载量
    path: '/operation_cheng_zai_liang_list',
    name: 'operation_cheng_zai_liang_list',
    component: () => import('../views/operation/operation_cheng_zai_liang_list.vue'),
  },
  { //运维管理》发送量/价格
    path: '/operation_send_and_price',
    name: 'operation_send_and_price',
    component: () => import('../views/operation/operation_send_and_price.vue'),
  },
  { //运维管理》平台综合数据
    path: '/operation_qxt_data',
    name: 'operation_qxt_data',
    component: () => import('../views/operation/operation_qxt_data.vue'),
  },
  { //运维管理》三方黑名单统计
    path: '/operation_black_three_stat',
    name: 'operation_black_three_stat',
    component: () => import('../views/operation/operation_black_three_stat.vue'),
  },
  { //运维管理》手机号空号查询统计
    path: '/operation_phone_check_stat',
    name: 'operation_phone_check_stat',
    component: () => import('../views/operation/operation_phone_check_stat.vue'),
  },
  {//短信主端口号
    path: '/sms_port',
    name: 'sms_port',
    component: () => import('../views/operation/sms_port/sms_port_list.vue'),
  },
  {//短信主端口号》短信子端口号列表
    path: '/sub_sms_port_list/:id',
    name: 'sub_sms_port_list',
    component: () => import('../views/operation/sms_port/sub_sms_port_list.vue'),
  },
  {//子码导签名
    path: '/export_signword_index',
    name: 'export_signword_index',
    component: () => import('../views/operation/export_signword/export_signword_index.vue'),
  },
  {//子码导签名通道列表
    path: '/export_channel_list',
    name: 'export_channel_list',
    component: () => import('../views/operation/export_signword/export_channel_list.vue'),
  },
  {//子码导签名下载
    path: '/export_signword_download',
    name: 'export_signword_download',
    component: () => import('../views/operation/export_signword/export_signword_download.vue'),
  },
  {//多媒体消息
    path: '/media',
    name: 'media',
    component: () => import('../views/media/media_index.vue'),
  },
  {//多媒体消息》签名管理
    path: '/media_sign/:id',
    name: 'media_sign',
    component: () => import('../views/media/media_sign.vue'),
  },
  {//多媒体消息》模板管理
    path: '/media_template/:id',
    name: 'media_template',
    component: () => import('../views/media/media_template.vue'),
  },
  {//多媒体消息》浙江启方模板管理
    path: '/media_template_qf/:id',
    name: 'media_template_qf',
    component: () => import('../views/media_qf/media_template.vue'),
  },
  {//多媒体消息》北京修治签名管理
    path: '/media_sign_xz/:id',
    name: 'media_sign_xz',
    component: () => import('../views/media_xz/media_sign.vue'),
  },
  {//多媒体消息》北京修治模板管理
    path: '/media_template_xz/:id',
    name: 'media_template_xz',
    component: () => import('../views/media_xz/media_template.vue'),
  },
  {//多媒体消息》上海移动直连签名管理
    path: '/media_sign_sh/:id',
    name: 'media_sign_sh',
    component: () => import('../views/media_sh/media_sign.vue'),
  },
  {//多媒体消息》上海移动直连模板管理
    path: '/media_template_sh/:id',
    name: 'media_template_sh',
    component: () => import('../views/media_sh/media_template.vue'),
  },
  {//多媒体消息》浙江移动直连签名管理
    path: '/media_sign_zj/:id',
    name: 'media_sign_zj',
    component: () => import('../views/media_zj/media_sign.vue'),
  },
  {//多媒体消息》浙江移动直连模板管理
    path: '/media_template_zj/:id',
    name: 'media_template_zj',
    component: () => import('../views/media_zj/media_template.vue'),
  },
  {//多媒体消息》中国联通数字短信平台签名管理
    path: '/media_sign_cucc/:id',
    name: 'media_sign_cucc',
    component: () => import('../views/media_cucc/media_sign.vue'),
  },
  {//多媒体消息》中国联通数字短信平台模版管理
    path: '/media_template_cucc/:id',
    name: 'media_template_cucc',
    component: () => import('../views/media_cucc/media_template.vue'),
  },
  {//多媒体消息》Thunder 5G SMS签名管理
    path: '/media_sign_thunder/:id',
    name: 'media_sign_thunder',
    component: () => import('../views/media_thunder/media_sign.vue'),
  },
  {//多媒体消息》Thunder 5G SMS模版管理
    path: '/media_template_thunder/:id',
    name: 'media_template_thunder',
    component: () => import('../views/media_thunder/media_template.vue'),
  },
  {//控价管理
    path: '/control_index',
    name: 'control_index',
    component: () => import('../views/user_control/control_index.vue'),
  },
  {//控价管理》控价管理
    path: '/user_control',
    name: 'user_control',
    component: () => import('../views/user_control/user_control.vue'),
  },
  {//控价管理》失败转成功配置
    path: '/fail_state_set',
    name: 'fail_state_set',
    component: () => import('../views/user_control/fail_state_set.vue'),
  },
   {//实时数据
    path: '/index_real_data',
    name: 'index_real_data',
    component: () => import('../views/real_data/index_real_data.vue'),
  },
   {//实时数据》客户实时数据
    path: '/cpid_real_data_list',
    name: 'cpid_real_data_list',
    component: () => import('../views/real_data/cpid_real_data_list.vue'),
  },{//实时数据》通道实时数据
    path: '/channel_real_data_list',
    name: 'channel_real_data_list',
    component: () => import('../views/real_data/channel_real_data_list.vue'),
  },{//客户未知状态转化管理》
    path: '/unknown_state_change_list',
    name: 'unknown_state_change_list',
    component: () => import('../views/user_unknown_state_change/unknown_state_change_list.vue'),
  },{//系统限制
    path: '/index_system_control',
    name: 'index_system_control',
    component: () => import('../views/system_control/index_system_control.vue'),
  },{//客户发送量限制
    path: '/cpid_send_num_limit_list',
    name: 'cpid_send_num_limit_list',
    component: () => import('../views/system_control/cpid_send_num_limit_list.vue'),
  },{//通道发送量限制
    path: '/channel_send_num_limit_list',
    name: 'channel_send_num_limit_list',
    component: () => import('../views/system_control/channel_send_num_limit_list.vue'),
  },
  

  //------------国际---开始-----------------------------------------------
  {//国际-菜单页
    path: '/inter_index',
    name: 'inter_index',
    component: () => import('../views/inter/index.vue'),
  },
  {//国际-客户管理页
    path: '/inter_user_list',
    name: 'inter_user_list',
    component: () => import('../views/inter/inter_user/inter_user_list.vue'),
  },
   { //国际-客户详情
    path: '/inter_user_detail/:id',
    name: 'inter_user_detail',
    component: () => import('../views/inter/inter_user/inter_user_detail.vue'),
  },
  { //国际-下行查询
    path: '/inter_mt_list',
    name: 'inter_mt_list',
    component: () => import('../views/inter/inter_mt/inter_mt_list.vue'),
  },
  { //国际-统计
    path: '/inter_count_list',
    name: 'inter_count_list',
    component: () => import('../views/inter/inter_count/inter_count_list.vue'),
  },
  //------------国际---结束------------------------------------------------


  
  

  

  

  

  
  

]
const router = new VueRouter({
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    };
  }
})

export default router