<template>
  <div id="app">
    <!-- 缓存 -->
    <keep-alive>
      <router-view />
      <!-- 公告 -->
      <Notice></Notice>
    </keep-alive>
  </div>
  
</template>
<script>
import Notice from './components/notice/notice.vue';
import API from './api/api.js';

export default {

    components: {
        Notice
    },
    
    data(){
        return{
            
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
      this.init();
      //this.el_select_iphone_bug();
    },
    //方法
    methods: {
        init(){
          //是移动端,针对el-select的bug修复
          if(API.judgeIsMobile()){//先判断是否是移动端
            console.log("是移动端,针对el-select的bug修复-----------");
            let clientHeight = document.body.clientHeight;
            var el_select_dropdown_max_height = clientHeight*0.25;
            var style = document.createElement("style");
            style.appendChild(document.createTextNode(".el-select-dropdown__wrap{max-height: "+el_select_dropdown_max_height+"px !important;text-align: right;}"));
            // style.appendChild(document.createTextNode(".el_select_text_align_right{text-align: right;}"));

            var head = document.getElementsByTagName("head")[0];
            head.appendChild(style);
          }
        },
        // el_select_iphone_bug(){
        //   console.log("执行了el_select_iphone_bug");
        //     Array.from(document.getElementsByClassName('el-select')).forEach((item) => {
        //       item.children[0].children[0].removeAttribute('readOnly')
        //       item.children[0].children[0].onblur = function () {
        //         let _this = this
        //         setTimeout(() => {
        //           _this.removeAttribute('readOnly')
        //         }, 200)
        //       }
        //     })
        // },
    }
};


</script>
<style lang="scss" scoped>
  html,
  body,
  #app {
    height: 100%;
  }
</style>

<style lang="scss">
   // to fix 在ios设备中，el-select组件下拉框，点击2次才能选中问题。
  .el-scrollbar {
    .el-scrollbar__bar {
        opacity: 1 !important;
    }
  }
</style>


