import {
    get,
    post
} from '../../axios'

import axios from 'axios';
import router from '../router/route.js'

import { Loading } from 'element-ui';




export default {
    
    //获取测试url地址
    getPageUrl(){
        let url_req;
        let url = location.href;
        let url_index = url.indexOf("manage_page");
        if (url_index > -1) {
            url_req = url.slice(0, url.indexOf("manage_page"));
        } else {
            url_req = location.origin;
        }
        return url_req;
    },
    //获取接口域名地址
    getInterfaceUrl(){
        return axios.defaults.baseURL;
    },

    //判断是否是移动端  true:是
    judgeIsMobile() {
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
        return flag;
    },

 

    //跳转到登录页面
    userLogin() {
        //let page_url = this.getPageUrl(); //获取测试url地址
        //location.href = page_url+"wap/html/manage/login/login.htm";
        router.push('/');
    },

    //跳转到主页面
    menu() {
        //let page_url = this.getPageUrl(); //获取测试url地址
        //location.href = page_url+"wap/html/manage/login/index.htm";
        
        router.push('/index');
    },

    //路由跳转
    router_to(router_url) {
        router.push(router_url);
    },


    // //跳转内链接登录页
    // linkInnerAddRouter() {
    //     router.push('/link_inner_add')
    // },

    getImage(params) {
        return post("getImage", params);
    },
    
    ManagerUserServlet(params) {
        return post("ManagerUserServlet", params);
    },
    
    UserServlet(params) {
        return post("UserServlet", params);
    },
    UserShuntServlet(params) {
        return post("UserShuntServlet", params);
    },


    CompanyServlet(params) {//客户的公司
        return post("CompanyServlet", params);
    },

    AnnounceServlet(params) {//公告
        return post("AnnounceServlet", params);
    },

    KeywordsServlet(params){//过滤词
        return post("KeywordsServlet", params);
    },

    SignLibraryServlet(params){//签名库
        return post("SignLibraryServlet", params);
    },

    ProvinceServlet(params){//省份
        return post("ProvinceServlet", params);
    },

    ControlStrategyServlet(params){//预警策略
        return post("ControlStrategyServlet", params);
    },

    LabelServlet(params){//标签
        return post("LabelServlet", params);
    },

    ChannelServlet(params){//通道
        return post("ChannelServlet", params);
    },
    UserPriceServlet(params){//客户价格
        return post("UserPriceServlet", params);
    },
    
    ChannelCompanyServlet(params){//通道公司
        return post("ChannelCompanyServlet", params);
    },

    ChannelPriceServlet(params){//通道价格
        return post("ChannelPriceServlet", params);
    },
    
    ChannelStateEscapeServlet(params){//返回状态转义
        return post("ChannelStateEscapeServlet", params);
    },
    ChannelStateSyncServlet(params){//企信通平台失败状态转义策略表相关接口
        return post("ChannelStateSyncServlet", params);
    },
    ChannelStateUnifyServlet(params){//企信通平台自定义失败状态表相关接口
        return post("ChannelStateUnifyServlet", params);
    },
    ChannelRateServlet(params){//空号，活跃号检测
        return post("ChannelRateServlet", params);
    },
    ChannelBackhaulServlet(params){//联通畅游数字营销_效果回传配置表相关接口
        return post("ChannelBackhaulServlet", params);
    },

    OperationManagerServlet(params){//运维管理
        return post("OperationManagerServlet", params);
    },

    MediaSignServlet(params){//多媒体签名管理
        return post("MediaSignServlet", params);
    },
    MediaTemplateServlet(params){//多媒体模板管理
        return post("MediaTemplateServlet", params);
    },
    IndustrySignServlet(params){//多媒体消息：安全行业分类
        return post("IndustrySignServlet", params);
    },
    UserPriceControlServlet(params){//控价管理
        return post("UserPriceControlServlet", params);
    },
    DataRealServlet(params){//实时数据接口
        return post("DataRealServlet", params);
    },
    UserUnknownStateChangeServlet(params){//客户未知状态转化接口
        return post("UserUnknownStateChangeServlet", params);
    },
    SystemControlServlet(params){//系统限制
        return post("SystemControlServlet", params);
    },



    //国际接口----------开始------------------------------
    Int_UserServlet(params){//国际客户接口
        return post("Int_UserServlet", params);
    },
    Int_CountServlet(params){//国际统计接口
        return post("Int_CountServlet", params);
    },
    Int_MtServlet(params){//国际下行接口
        return post("Int_MtServlet", params);
    },


    //国际接口----------结束------------------------------

    


    //判断是否为空
    isEmtry(str){
        if (str == null || str==undefined){
            return true;
        }else{
            if(typeof(str) == 'number' || typeof(str) == 'boolean'){
                return false;
            }else{
                if(str == ''){
                    return true;
                }else{
                    return false;
                }
            }
        }
    },

    //获取周一的日期  date为时间
    getFirstDayOfWeek(date) {
        var day = date.getDay() || 7;
        return new Date(date.getFullYear(),date.getMonth(),date.getDate() + 1 - day);
    },
    //获取周日的日期  date为时间
    getLastDayOfWeek(date) {
        var day = date.getDay() || 7;
        return new Date(date.getFullYear(),date.getMonth(),date.getDate() + 7 - day);
    },
   




    //加载中-展示
    loading_show() {
        const loading = Loading.service({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });
        return loading;
    },
    //加载中-关闭
    loading_close(loading) {
        if(loading){
            loading.close();
        }
    },

    


    

}