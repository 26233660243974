export const setCookie = (c_name, value) => {
    // var d = new Date();
    // d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    // var expires = "expires=" + d.toGMTString();
    // document.cookie = cname + "=" + cvalue + "; " + expires;


    var Days = 1; //此 cookie 将被保存 30 天
	var exp = new Date(); //new Date("December 31, 9998");
	exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
	document.cookie = c_name + "=" + escape(value) + ((1 == null) ? "" : ";expires=" + exp.toGMTString()) + ";path=/";
}

export const getCookie = function (c_name) {
    // var name = cname + "=";
    // var ca = document.cookie.split(';');
    // for (var i = 0; i < ca.length; i++) {
    //     var c = ca[i].trim();
    //     if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
    // }
    // return "";

    if (document.cookie.length > 0) {
		var c_start = document.cookie.indexOf(c_name + "=")
		if (c_start != -1) {
			c_start = c_start + c_name.length + 1
			var c_end = document.cookie.indexOf(";", c_start)
			if (c_end == -1) c_end = document.cookie.length
			return unescape(document.cookie.substring(c_start, c_end))

		}
	}
	return ""
}
export const delCookie = (name) => {
    // var exp = new Date();
    // exp.setTime(exp.getTime() - 1);
    // var cval = getCookie(cname);
    // if (cval != null) {
    //     document.cookie = cname + "=" + cval + ";expires=" + exp.toGMTString();
    // }
    var exp = new Date();
	exp.setTime(exp.getTime() - 1);
	var cval = getCookie(name);
	if (cval != null)
		document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString() + ";path=/";
}
export function htmlDecode(text) {
    //1.首先动态创建一个容器标签元素，如DIV
    var temp = document.createElement("div");
    //2.然后将要转换的字符串设置为这个元素的innerHTML(ie，火狐，google都支持)
    temp.innerHTML = text;
    //3.最后返回这个元素的innerText(ie支持)或者textContent(火狐，google支持)，即得到经过HTML解码的字符串了。
    var output = temp.innerText || temp.textContent;
    //temp.parentNode.removeChild(temp);//删除
    temp = null;
    return output;
}

export function isMobile(mobile) {
    return /^1[3-9]\d{9}$/.test(mobile)
}



/**************************************时间格式化处理************************************/
export  function dateFtt(date,fmt)   
{ //author: meizz   
  var o = {   
    "M+" : date.getMonth()+1,                 //月份   
    "d+" : date.getDate(),                    //日   
    "h+" : date.getHours(),                   //小时   
    "m+" : date.getMinutes(),                 //分   
    "s+" : date.getSeconds(),                 //秒   
    "q+" : Math.floor((date.getMonth()+3)/3), //季度   
    "S"  : date.getMilliseconds()             //毫秒   
  };   
  if(/(y+)/.test(fmt))   
    fmt=fmt.replace(RegExp.$1, (date.getFullYear()+"").substr(4 - RegExp.$1.length));   
  for(var k in o)   
    if(new RegExp("("+ k +")").test(fmt))   
  fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));   
  return fmt;   
} 

//guid
export function my_guid() {
    var d = new Date().getTime();
    
    return d;
}

//本地存储-存放
export function setItem(key,value) {
    localStorage.setItem(key,value);
    sessionStorage.setItem(key,value);
    setCookie(key,value);
}
//本地存储-获取
export function getItem(key) {
    var v = localStorage.getItem(key);
    if(v == null){
        v = sessionStorage.getItem(key);
        if(v == null){
            v = getCookie(key);
        }
    }
    return v;
}
//本地存储-删除
export function removeItem(key) {
    localStorage.removeItem(key);
    sessionStorage.removeItem(key);
    delCookie(key);
}

//本地存储-全部删除
export function removeAllItem() {
    localStorage.clear();
    sessionStorage.clear();
}

//本地存储-存放-登录信息
export function setItemSession(value) {
    var key = "user_login_session_20220421";
    localStorage.setItem(key,value);
}
//本地存储-获取-登录信息
export function getItemSession() {
    var key = "user_login_session_20220421";
    return localStorage.getItem(key);
}
//本地存储-移除-登录信息
export function removeItemSession() {
    var key = "user_login_session_20220421";
    return localStorage.removeItem(key);
}


//获取cookie中登陆的session信息
export function getCookieSession() {
    var key = "qxt_session";
    // return "admin_zsx_72CDFF8FBF54DCFFC47C1330049835FA";
    return getCookie(key);
}



