<template> 
<div>
    <!-- 公告页 -->
    <el-dialog class="cur_dialog" title="" center :visible.sync="dialog_visible"  fullscreen destroy-on-close :show-close="false" >
        <!-- 头 -->
        <el-row class="head_top body_color" >
            <div class="head_top_back cursor"><i class="el-icon-arrow-left" @click="back()"></i></div>
            <div class="head_top_right">
                <el-button type="primary" @click="read_page()" size="mini">
                    阅读本页
                </el-button>
            </div>
            
            <div class="head_top_title">公告页</div>

        </el-row>


         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

            <el-row style="overflow: auto;padding-right:10px;" :style="{height: (content_heigth+'px')}">
                <el-row>
                    共{{unReadNum}}条未读
                </el-row>
                <el-row style="margin-top:5px;">
                    共{{readNum}}条已读
                </el-row>
                

                <template v-for="item in tableData">
                    <!-- 卡片 shadow="hover"鼠标悬浮时显示卡片阴影 -->
                    <el-card class="box-card" shadow="hover" style="margin-top:10px;">
                        <div slot="header" class="clearfix">
                            <span>ID:{{ item.id }}</span>

                            <el-link @click="read(item)" type="primary" :underline="false" style="float: right;margin-right:10px;">
                                阅读
                            </el-link>
                        </div>
                        <el-row type="flex" align="top">
                            <div class="card_span_left">内容</div>
                            <div :style="{width:(card_span_right_width+'px')}" class="card_span_right chao_wen_zi_div_hide">{{item.content}}</div>
                        </el-row>

                        <el-row type="flex" align="top">
                            <div class="card_span_left">发布者</div>
                            <div :style="{width:(card_span_right_width+'px')}" class="card_span_right">{{item.send_login}}</div>
                        </el-row>

                        <el-row type="flex" align="top">
                            <div class="card_span_left">录入时间</div>
                            <div :style="{width:(card_span_right_width+'px')}" class="card_span_right">{{item.createtime}}</div>
                        </el-row>

                        <el-row type="flex" align="top">
                            <div class="card_span_left">状态</div>
                            <div v-if="item.state == '已读'" :style="{width:(card_span_right_width+'px')}" class="card_span_right">{{item.state}}</div>
                            <div v-if="item.state == '未读'" :style="{width:(card_span_right_width+'px')}" class="card_span_right" style="color:red">{{item.state}}</div>
                        </el-row>
                    </el-card>
                </template>


            </el-row>

            <el-row id="pagination_id_notice" style="padding:10px 0 0 0;text-align:center;line-height:26px;">
                <!-- 分页  layout="total, sizes, prev, pager, next, jumper"   small-->
                <el-pagination background  
                    layout="total, prev, pager, next,jumper"
                    :total="tableData_total"
                    small
                    :pager-count="5"
                    @size-change="pageSizeChange"
                    @current-change="pageCurrentChange"
                    :page-sizes="[5,10, 20, 30, 40]"
                     :page-size="10"
                     style="white-space: normal !important;">
                </el-pagination>
            </el-row>
            

           
            

        </el-row>
        

    </el-dialog>

    <!-- 阅读详情页  -->
    <el-dialog class="cur_dialog2" title="阅读页" :visible.sync="detail_dialog_visible" width="95%" :before-close="detail_dialog_handleClose" center destroy-on-close >

        <!-- 卡片 shadow="hover"鼠标悬浮时显示卡片阴影 -->
        <el-card class="box-card" shadow="hover">
            <div slot="header" class="clearfix">
                <span>ID:{{ detail_item.id }}</span>
            </div>
            <el-row type="flex" align="top">
                <div class="card_span_left">内容</div>
                <div :style="{width:(card_span_right_width+'px')}" class="card_span_right">{{detail_item.content}}</div>
            </el-row>

            <el-row type="flex" align="top">
                <div class="card_span_left">发布者</div>
                <div :style="{width:(card_span_right_width+'px')}" class="card_span_right">{{detail_item.send_login}}</div>
            </el-row>

            <el-row type="flex" align="top">
                <div class="card_span_left">录入时间</div>
                <div :style="{width:(card_span_right_width+'px')}" class="card_span_right">{{detail_item.createtime}}</div>
            </el-row>
            
        </el-card>
    </el-dialog>
</div>
</template>
<script>

import API from '../../api/api';

export default {
    props:{
        prop_change_i:0,
    },
    watch:{//监听props
        prop_change_i(val){
            this.dialog_visible = true;//弹出框是否可见 
            //数据清除
            //this.dataClear();

            setTimeout(() => {

                //获取数据
                this.submit_cha_xun();

            }, 1);
        }
    },

    data(){
        return{
            dialog_visible: false,//弹出框是否可见  false:不可见
            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度

            readNum:0,//已读数
            unReadNum:0,//未读数

            indexPage:1,//分页 第几页
            pageSize:10,//分页 一页的数量值
            
            tableData:[],//表格数据
            tableData_total:0,//表格数据总量
            pagination_height:40,//分页的高度


            card_span_right_width:100,//卡片右边的宽度

            detail_dialog_visible:false,//详情页弹出框是否可见  false:不可见
            detail_item:{//详情页对象
                id: 0,
		        login: "",
                content: "",
                state: "",
                send_login: "",
                createtime: ""
            },
            
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();
    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 130);

            this.card_span_right_width = this.content_width - 100;
        },
        //获取数据
        submit_cha_xun(){
            //请求接口
            API.AnnounceServlet({
                param: "list",
                indexPage:this.indexPage,
                pageSize:this.pageSize,

            }).then((res) => {
                //console.log(res);
                if (res.recode === 0) {
                    this.tableData = res.list;
                    this.tableData_total = res.totalNum;

                    this.readNum = res.readNum;//已读数
                    this.unReadNum = res.unReadNum;//未读数

                    this.$nextTick(function(){
                        //获取分页的高度
                        this.pagination_height = document.getElementById('pagination_id_notice').clientHeight;
                    });

                    
                } else {
                }
                
            });
        },
        //分页大小发生改变调用
        pageSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.pageSize = val;
            //查询-- 请求接口
            this.submit_cha_xun();
        },
        //分页 当前页 发生改变调用
        pageCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.indexPage = val;
            //查询-- 请求接口
            this.submit_cha_xun();
        },
       

        //阅读本页
        read_page(){
            console.log("阅读本页");
            let ids = "";
            for(let i =0;i<this.tableData.length;i++){
                var item = this.tableData[i];
                if(item.state === "未读"){
                    if(ids === ""){
                         ids += item.id;
                    }else{
                        ids += ","+item.id;
                    }
                }
            }
            if(ids == ""){
                this.$message.warning("本页没有未读信息，请核实");
            }else{
                 //请求接口-阅读
                API.AnnounceServlet({
                    param: "upd",
                    id:ids,
                }).then((res) => {
                    if (res.recode === 0) {
                        //获取数据
                        this.submit_cha_xun();
                    } else {
                    }
                });
            }
        },

        //阅读
        read(item){
            console.log("阅读");
            this.detail_item = item;
            this.detail_dialog_visible=true;//详情页弹出框是否可见  false:不可见

            if(item.state === "未读"){
                 //请求接口-阅读
                API.AnnounceServlet({
                    param: "upd",
                    id:item.id,
                }).then((res) => {
                    if (res.recode === 0) {
                    } else {
                    }
                });
            }
        },
        //阅读框 关闭之前 执行方法
        detail_dialog_handleClose(done){

            if(this.detail_item.state === "未读"){
                //获取数据
                this.submit_cha_xun();
            }

            done();//关闭弹框
        },

        //关闭
        back(){
            this.dialog_visible = false;//弹出框是否可见   false:不可见

            this.$emit("result","");//回调
        },
    }
};

</script>

<style scoped>
/* 增加或编辑弹框中内容距离标题的距离 */
.cur_dialog >>>.el-dialog__body {
    padding: 0px 0px;
}
.cur_dialog >>>.el-dialog__header {
    padding: 0px 0px;
}
/* 卡片 */
.cur_dialog>>>.el-card__header{
    padding: 8px 20px;
}

/* 卡片 */
.cur_dialog2>>>.el-card__header{
    padding: 8px 20px;
}

/* 卡片内容中，左边样式 */
.card_span_left{
    width:100px;
    text-align: right;
    padding-right: 10px;
}


</style>