import axios from 'axios';
import qs from 'qs'
import API from './src/api/api'
import router from './src/router/route.js'
import {
    htmlDecode,
    getItem
} from './src/api/tool'
import { MessageBox,Message } from "element-ui";

axios.defaults.timeout = 100000;//超时时间 100秒




let url_req;
let url = location.href;
let url_index = url.indexOf("manage_page");
if (url_index > -1) {
    url_req = url.slice(0, url.indexOf("manage_page"));
} else {
    url_req = location.origin;
}

if(url_req.indexOf('http://112.124.1.196') == 0 || url_req.indexOf('.hetatech.cn/') > -1){//河滔
    axios.defaults.baseURL = "http://service.hetatech.cn";//生产服务器+线上
}else{
    //汉生
    if(url_req.indexOf('http://localhost') == 0 || url_req.indexOf('http://192.168.') == 0 || url_req.indexOf('http://172.28.') == 0){//本地
        axios.defaults.baseURL = "http://120.55.90.221:80/service";//测试服务器
    }else if(url_req.indexOf('http://120.55.90.221') == 0 ){//测试服务器
        axios.defaults.baseURL = "http://120.55.90.221:80/service";
    }else{
        axios.defaults.baseURL = "http://service.hesenscloud.com";//生产服务器+线上
    }
}



axios.defaults.withCredentials = true;//使请求带HttpSession的id
//http request 拦截器
axios.interceptors.request.use(
    config => {
        config.data = qs.stringify(config.data);
        
        config.headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
        }
        config.withCredentials = true
        let token = localStorage.getItem('token');
        if (token) config.headers['Authorization'] = token;
        return config;
    },
    error => {
        //console.log(error)
        return Promise.reject(error);
    }
);
//http response 拦截器
axios.interceptors.response.use(
    res => {
        //如果返回的是文件流，直接返回
        const headers = res.headers;
        if (headers['content-type'] === 'application/octet-stream') {
            return res
        }
        let indexD = res.data.indexOf("{");
        let indexD2 = res.data.lastIndexOf("}"); 
        if(indexD > 0 && indexD2>indexD){
            let myData = res.data.substring(indexD,indexD2+1);
            //onsole.log(myData)   
            res.data = JSON.parse(myData);
        }

        //res.data = JSON.parse(res.data);

        // res.data = JSON.parse(htmlDecode(res.data))
        return res;
    },
    error => {
        //console.log(error.response)     
        let msg = ""
        if (error && error.response) {
            switch (error.response.status) {
                case 400:
                    msg = "错误请求"
                    break;
                case 401:
                    msg = "未授权，请重新登录"
                    break;
                case 403:
                    msg = "拒绝访问"
                    break;
                case 404:
                    msg = "请求错误，未找到该资源"
                    break;
                case 405:
                    msg = "请求方式未允许"
                    break;
                case 408:
                    msg = "请求超时"
                    break;
                case 500:
                    msg = "服务器出错"
                    break;
                case 501:
                    msg = "网络未实现"
                    break;
                case 502:
                    msg = "网络错误"
                    break;
                case 503:
                    msg = "服务不可用"
                    break;
                case 504:
                    msg = "网络超时"
                    break;
                case 505:
                    msg = "http版本不支持该请求"
                    break;
                default:
                    msg = "连接错误" + res.status
            }
        } else {
            msg = "未知错误"
            if (error.message === "timeout of 5000ms exceeded") {
                msg = "网络请求超时,请刷新重试"
            }
        }
        // API.clearLoading()

        //Message.error(msg);
        // Modal.error({
        //     title: "提示",
        //     content: msg,
        // });
        return Promise.reject(error)
    }
)


/*
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function get(url, params = {}) {
    // params.session = getCookie('hcx-session')
    params.callback = (new Date().getTime()).toString() + Math.ceil(Math.random() * 100);
    params.session = getItem("qxt_session");//本地存储-获取-登录信息

    // params.session = getCookieSession();//获取cookie中登陆的session信息

    var loading_onoff = params.loading_onoff;
    var loading_onoff_b = true;//加载中是否显示  true:显示
    if(typeof(loading_onoff)  != 'undefined' && !loading_onoff){
        loading_onoff_b = false; 
    }
    //console.log("loading_onoff_b:"+loading_onoff_b)
    var loading;
    if(loading_onoff){
        loading = API.loading_show();//加载中-展示
    }else{
        loading = {};
    }

    //const loading = API.loading_show();//加载中-展示
    return new Promise((resolve, reject) => {
        if(params.session == ""){
            if(loading_onoff){
                API.loading_close(loading);//加载中-关闭
            }
            Message.warning("登录超时，请重新登录");
            //跳转到页面
            API.userLogin();
            
        }else{
            axios.get(url, {params: params }).then(response => {
                if(loading_onoff){
                    API.loading_close(loading);//加载中-关闭
                }
                console.log("响应："+response.data.recode);
                if (response.data.recode === 0) {
                    resolve(response.data);
                } else if (response.data.recode === -1) {
                    //delCookie('hcx-session')
                   
                    MessageBox.alert(response.data.text,"提示",{
                        confirmButtonText: "确定",
                        showClose:false,
                        type:"error", //类型
                        
                        center: true, //是否居中布局
                        callback: action => {
                            
                        }
                    });
                    //setItem("menu_cur","api");
                    //跳转到页面
                    API.userLogin();

                    
                }  else if (response.data.recode === 2) {
                    //delCookie('hcx-session')
                    
                    Message.error(response.data.text);
                    //跳转到页面
                    resolve(response.data);

                } else {
                    if(loading_onoff){
                        MessageBox.alert(response.data.text,"提示",{
                            confirmButtonText: "确定",
                            showClose:false,
                            type:"error", //类型
                            
                            center: true, //是否居中布局
                            callback: action => {
                                //跳转到页面
                                resolve(response.data);
                            }
                        });
                    }
                    
                }
            })
            .catch(err => {
                if(loading_onoff){
                    API.loading_close(loading);//加载中-关闭
                    Message.error("服务繁忙，请稍后再试");
                }
                //reject(err)
                
            })
        }
        
    })
}


/*
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */
var req_i = 0;
export function post(url, params = {}) {
    // data.session = getCookie('hcx-session')
    req_i ++;
    params.callback = (new Date().getTime()).toString() + Math.ceil(Math.random() * 10)+"_"+req_i;
    params.session = getItem("qxt_session");//本地存储-获取-登录信息

    // params.session = getCookieSession();//获取cookie中登陆的session信息
    
    var loading_onoff = params.loading_onoff;
    var loading_onoff_b = true;//加载中是否显示  true:显示
    if(typeof(loading_onoff)  != 'undefined' && !loading_onoff){
        loading_onoff_b = false; 
    }
    //console.log("loading_onoff_b:"+loading_onoff_b)
    var loading;
    if(loading_onoff_b){
        loading = API.loading_show();//加载中-展示
    }else{
        loading = {};
    }

    //const loading = API.loading_show();//加载中-展示
    return new Promise((resolve, reject) => {
        let is_login_page = false;//是否是登录页
        if(url == "getImage" || (url == 'ManagerUserServlet' && params.param == 'login')){
            is_login_page = true;//是登录页
        }
        if((params.session == "" || params.session == null) && !is_login_page){
            params.session = getItem("qxt_session");//本地存储-获取-登录信息
        }
        let cur_route_path = router.currentRoute.fullPath;//当前路由地址

        // console.log("params.session:"+params.session+" is_login_page:"+is_login_page);
        if((params.session == "" || params.session == null) && !is_login_page){
            if(loading_onoff_b){
                API.loading_close(loading);//加载中-关闭
            }

            if(cur_route_path == "/"){//登录页

            }else{
                Message.warning("登录超时，请重新登录");
                //跳转到页面
                API.userLogin();
            }
            
        }else{
            axios.post(url, params).then(response => {
                if(loading_onoff_b){
                    API.loading_close(loading);//加载中-关闭
                }
                if (response.data.recode === 0) {
                    resolve(response.data);
                } else if (response.data.recode === -1) {
                    if(cur_route_path == "/"){//登录页

                    }else{
                        Message.error(response.data.text);
                        //跳转到页面
                        API.userLogin();
                    }
                } else {
                    
                    if(loading_onoff_b && response.data.text){
                        MessageBox.alert(response.data.text,"提示",{
                            confirmButtonText: "确定",
                            showClose:false,
                            type:"error", //类型
                            
                            center: true, //是否居中布局
                            callback: action => {
                                //跳转到页面
                                resolve(response.data);
                            }
                        });
                    }
                }
            }).catch(err => {
                if(loading_onoff_b){
                    
                    API.loading_close(loading);//加载中-关闭
                    
                    Message.error("服务繁忙，请稍后再试");
                }
                console.log(err)
               
            })
        }

        
    })
}